import { Controller } from "stimulus"

export default class extends Controller {
  static targets = ["combinations", "toggleCheckbox", "variantStockForm"]
  static values = { id: Number }

  toggleForm() {
    const checkbox = this.toggleCheckboxTarget
    const form = this.variantStockFormTarget

    if (checkbox.checked) {
      form.style.display = "block"
    } else {
      form.style.display = "none"
    }
  }

  async updateCombinations() {
    const variantGroups = this.element.querySelectorAll('.variant-group');
    const selectedOptions = [];

    // Loop through each variant group
    variantGroups.forEach(variantGroup => {
      const options = [];

      // Loop through checkboxes within the variant group
      variantGroup.querySelectorAll('input[type="checkbox"]:checked').forEach(checkbox => {
        options.push({
          id: checkbox.value,
          name: checkbox.nextElementSibling.textContent.trim()
        });
      });

      // Add the selected options for the variant group
      selectedOptions.push(options);
    });

    // To show only selected options with data within them
    const compactedArray = selectedOptions.filter(subArray => subArray.length > 0);
    if (compactedArray.length > 0) {
      // Generate all possible combinations
      const combinations = this.generateCombinations(selectedOptions);
      const extractedValues = combinations.map(arrayOfObjects => {
        return arrayOfObjects.map(object => object.id);
      });
  
      // Include individual selected options
      if (combinations.length == 0) {
          this.renderNoCombinationsTable(compactedArray[0]);
          const arrayOfIds = compactedArray[0].map(obj => [obj.id]);
          await this.displayStockQuantities(arrayOfIds)
      } else {
        this.renderTable(combinations);
        await this.displayStockQuantities(extractedValues)
      }
    } else {
      this.combinationsTarget.innerHTML = null;
      console.log('No options selected')
    }
  }

  async displayStockQuantities(combinations) {
    let listingId = this.idValue
    const response = await fetch('/listing_variant_stocks', {
      method: 'POST',
      headers: {
        'Content-Type': 'application/json'
      },
      body: JSON.stringify({ combinations: combinations, listing_id: listingId })
    });

    const data = await response.json();

    // Iterate over the returned stock quantities and populate the form fields
    data.forEach((stock, index) => {
      const combinationRow = this.combinationsTarget.querySelector(`tr:nth-child(${index + 1})`);
      if (combinationRow) {
        const stockInput = combinationRow.querySelector(`input[name="stock[${stock.variant_option_ids}]"]`);
        if (stockInput) {
          stockInput.value = stock.stock_quantity;
        }

        const priceInput = combinationRow.querySelector(`input[name="price[${stock.variant_option_ids}]"]`);
        if (priceInput) {
          priceInput.value = stock.price;
        }

        const salePriceInput = combinationRow.querySelector(`input[name="sale_price[${stock.variant_option_ids}]"]`);
        if (salePriceInput) {
          salePriceInput.value = stock.sale_price;
        }

        const percentageDiscountInput = combinationRow.querySelector(`input[name="percentage_discount[${stock.variant_option_ids}]"]`);
        if (percentageDiscountInput) {
          percentageDiscountInput.value = stock.percentage_discount;
        }
      }
    });
  }

  renderTable(combinations) {
    let tableBody = '';
  
    if (combinations.length > 0) {
      // Render table for combinations
      tableBody = combinations.map((combination, index) => {
        const variantOptionIds = combination.map(option => option.id).join(',');
        return `
          <tr data-controller="margin-calculator">
            <td>${index + 1}</td>
            <td>${combination.map(option => option.name).join(', ')}</td>
            <td><input type="number" name="stock[${variantOptionIds}]" class="form-control" value=""></td>
            <td><input type="number" name="price[${variantOptionIds}]" class="form-control" value="" data-margin-calculator-target="price" data-action="input->margin-calculator#handleInputField" data-input-field="price"></td>
            <td><input type="number" name="sale_price[${variantOptionIds}]" class="form-control" value="" data-margin-calculator-target="salePrice" data-action="input->margin-calculator#handleInputField" data-input-field="salePrice"></td>
            <td><input type="number" step="any" name="percentage_discount[${variantOptionIds}]" class="form-control" value="" data-margin-calculator-target="percentageDiscount" data-action="input->margin-calculator#handleInputField" data-input-field="percentageDiscount"></td>
            <td></td>
          </tr>
        `;
      }).join('');
    } 
    this.combinationsTarget.innerHTML = tableBody;
  }

  renderNoCombinationsTable(combinations) {
    let tableBody = '';
      // Render table for individual selected options
      tableBody = combinations.map((option, index) => {
        return `
          <tr>
            <td>${index + 1}</td>
            <td>${option.name}</td>
            <td><input type="number" name="stock[${option.id}]" class="form-control"></td>
            <td><input type="number" name="price[${option.id}]" class="form-control" value=""></td>
            <td><input type="number" name="sale_price[${option.id}]" class="form-control" value=""></td>
            <td><input type="number" name="percentage_discount[${option.id}]" class="form-control" value=""></td>
            <td></td>
          </tr>
        `;
      }).join('');
      this.combinationsTarget.innerHTML = tableBody;
  }
  

  generateCombinations(arrays) {
    const combine = (arrays, prefix = []) => {
      if (!arrays.length) return [prefix];
      const [first, ...rest] = arrays;
      return first.flatMap(option => combine(rest, prefix.concat(option)));
    };
    return combine(arrays);
  }

  connect() {
    this.updateCombinations()
    this.toggleForm()
  }
}
